import Flickity from 'flickity';
require('flickity-imagesloaded');
require('flickity-fade');

function msToTimeString(ms) {
    let seconds = (ms / 1000) % 60;
    let minutes = Math.floor(ms / 1000 / 60) % 60;
    let hours = Math.floor(ms / 1000 / 60 / 60);

    seconds = ('0' + seconds).slice(-2);
    minutes = ('0' + minutes).slice(-2);
    hours = ('0' + hours).slice(-2);

    return `${hours}:${minutes}:${seconds}`;
}

/**
 * Hero Slider
 */
export default function HeroSlider() {
    const heroSliderElm = document.querySelector('.section--hero-slider .hero-slider__slides');
    if ( heroSliderElm ) {
        const flkty = new Flickity( heroSliderElm, {
            cellAlign: 'left',
            wrapAround: true,
            imagesLoaded: false,
            lazyLoad: 2,
            fade: true,
            prevNextButtons: false,
            autoPlay: 3500,
            pauseAutoPlayOnHover: false
        });

        // heroSliderElm.addEventListener('click', () => {
        //     heroSliderElm.parentElement.classList.add('autoplay-disabled');
        // });
    }
}
