import ActivateWqiScore from "../partials/activateWqiScore";
import $ from "jquery";
/**
 * Accordions
 */
export default function Accordions() {
	const $ = window.jQuery;

	//   $('.accordion-item-header').on('click', function () {
	//     const opened = $(this).attr('aria-expanded');
	//     const accordionTitle = $(this).find('.accordion-item__title').text();
	//     // console.log(opened);
	//     if (opened === 'true') {
	//       console.log('Activate:', `${accordionTitle} Map`);
	//     } else {
	//       ActivateWqiScore();
	//     }
	//   });

	const accordionItemTriggers = document.querySelectorAll(
		".accordion-item-header"
	);

	accordionItemTriggers.forEach(accordionItemTrigger => {
		accordionItemTrigger.addEventListener("click", () => {
			const isOpen = accordionItemTrigger.getAttribute("aria-expanded");
			const accordionTitle = accordionItemTrigger.querySelector(
				".accordion-item__title"
			).textContent;
			const accordionAttrValue = accordionItemTrigger
				.querySelector(".accordion-item__title")
				.getAttribute("data-element");

			if (isOpen === "true") {
				let filterRiver = decodeURI((RegExp("river" + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]);
				let filterLocation = decodeURI((RegExp("location" + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]);
				// console.log(`Activate:${accordionTitle.replace(/\s+/g, ' ')} Map`);
				var selectedDateValue = window.jQuery("#hdnDateField").val();
				var elementName = accordionAttrValue;
				//fetch data for map according to matching date of clicked element in application and parameters
				window.jQuery.get(
					apiPath + "return-map-data.php",
					{
						selectedDate: selectedDateValue,
				      	selectedLocation: filterLocation,
				      	selectedRiver: filterRiver
					},
					function(data, status) {
						if (elementName == "ph") {
							var legendImgUrl = "/assets/img/legend-ph.svg";
						} else if (elementName == "bod") {
							var legendImgUrl = "/assets/img/legend-bod.svg";
						} else if (elementName == "tc") {
							var legendImgUrl = "/assets/img/legend-tc.svg";
						} else if (elementName == "ec") {
							var legendImgUrl = "/assets/img/legend-ec.svg";
						} else if (elementName == "fc") {
							var legendImgUrl = "/assets/img/legend-fc.svg";
						} else if (elementName == "dissolved_oxygen") {
							var legendImgUrl = "/assets/img/legend-do.svg";
						} else if (elementName == "drinking") {
							var legendImgUrl =
								"/assets/img/legend-drinking.svg";
						} else if (elementName == "bathing") {
							var legendImgUrl =
								"/assets/img/legend-bathing.svg";
						} else if (elementName == "industrial") {
							var legendImgUrl =
								"/assets/img/legend-industrial.svg";
						}

						//remove all layers from map
						map.eachLayer(function(layer) {
							map.removeLayer(layer);
						});

						var parseResponse = JSON.parse(data);
						//leaflet map code
						if (parseResponse.length > 0) {
							//set longitude and color
							var latitude = parseResponse[0]["latitude"];
							var longitude = parseResponse[0]["longitude"];

							var redColor = "#BF4045";
							var greenColor = "#7EB21D";
							//when map loads
							if (latitude !== null && longitude !== null) {
								for (var i = 0; i < parseResponse.length; i++) {
									var tooltipText;
									//start: conditions
									if (elementName == "ph") {
										var tooltipText =
											parseResponse[i][elementName];
										if (
											parseResponse[i][elementName] >=
												6.5 &&
											parseResponse[i][elementName] <= 8.5
										) {
											var color = greenColor; // green
										} else if (
											parseResponse[i][elementName] >
												8.5 ||
											parseResponse[i][elementName] < 6.5
										) {
											var color = redColor; // red
										}
									} else if (elementName == "bod") {
										var tooltipText =
											parseResponse[i][elementName];
										// alert(tooltipText);
										if (
											parseResponse[i][elementName] >=
												2 &&
											parseResponse[i][elementName] <= 3
										) {
											var color = greenColor; // green
										} else if (
											parseResponse[i][elementName] > 3 ||
											parseResponse[i][elementName] < 2
										) {
											var color = redColor; // red
										}
									} else if (elementName == "tc") {
										var tooltipText =
											parseResponse[i][elementName];
										if (
											parseResponse[i][elementName] >=
												0 &&
											parseResponse[i][elementName] <= 500
										) {
											var color = greenColor; // green
										} else if (
											parseResponse[i][elementName] >
												500 ||
											parseResponse[i][elementName] < 0
										) {
											var color = redColor; // red
										}
									} else if (elementName == "ec") {
										var tooltipText =
											parseResponse[i][elementName];
										if (
											parseResponse[i][elementName] >=
												0 &&
											parseResponse[i][elementName] <=
												2250
										) {
											var color = greenColor; // green
										} else if (
											parseResponse[i][elementName] > 2250
										) {
											var color = redColor; // red
										}
									} else if (elementName == "fc") {
										var tooltipText =
											parseResponse[i][elementName];
										if (
											parseResponse[i][elementName] >=
												0 &&
											parseResponse[i][elementName] <= 500
										) {
											var color = greenColor; // green
										} else if (
											parseResponse[i][elementName] >
												500 ||
											parseResponse[i][elementName] < 0
										) {
											var color = redColor; // red
										}
									} else if (
										elementName == "dissolved_oxygen"
									) {
										var tooltipText =
											parseResponse[i][elementName];
										if (
											parseResponse[i][elementName] >=
												64 &&
											parseResponse[i][elementName] <= 77
										) {
											var color = greenColor; // green
										} else if (
											parseResponse[i][elementName] >
												77 ||
											parseResponse[i][elementName] < 64
										) {
											var color = redColor; // red
										}
									} else if (elementName == "drinking") {
										var tooltipText = "noTooltip";
										if (
											parseResponse[i]["drinking_median"] == 0
										) {
											var color = redColor; // red
										} else {
											var color = greenColor; // green
										}
									} else if (elementName == "bathing") {
										var tooltipText = "noTooltip";
										if (
											parseResponse[i]["bathing_median"] == 0
										) {
											var color = redColor; // red
										} else {
											var color = greenColor; // green
										}
									} else if (elementName == "industrial") {
										var tooltipText = "noTooltip";
										if (
											parseResponse[i]["industrial_median"] == 0
										) {
											var color = redColor; // red
										} else {
											var color = greenColor; // green
										}
									}
									//end: conditions

									//add circles for the
									var lat = parseResponse[i]["latitude"];
									var long = parseResponse[i]["longitude"];

									// element value exists
									if( tooltipText == "noTooltip" ) {
										var circle = L.circleMarker([lat, long], {
											stroke: true,
											color: "#ffffff",
											weight: 0.2,
											fillColor: color,
											fillOpacity: 1,
											radius: 10
										})
											.addTo(map)
											.addTo(map);

										//change cursor
										window.jQuery(".leaflet-interactive").css("cursor", "default");
									} else if (tooltipText != null) {
										var circle = L.circleMarker([lat, long], {
											stroke: true,
											color: "#ffffff",
											weight: 0.2,
											fillColor: color,
											fillOpacity: 1,
											radius: 10
										})
											.bindTooltip(tooltipText)
											.addTo(map)
											.addTo(map);
									}
								}
							} else {
								var latitude = 13.09187; //default
								var longitude = 77.60722; //default
							}
						} else {
							var latitude = 13.09187; //default
							var longitude = 77.60722; //default
						}

						//start: mapbox title layer
						//access token of mapbox (given by ryanreynolds)
						L.mapbox.accessToken =
							"pk.eyJ1IjoicnlhbnJleW5vbGRzIiwiYSI6ImNqemRkemRjdzA0dDQzYmp2d21waWR5NDkifQ.Sddp5xSThFCTBvUFqID1ZA";
						map.setView([latitude, longitude], 13);

						L.tileLayer(
							"https://api.mapbox.com/styles/v1/ryanreynolds/cjzde2kss30zh1cufkom0pfl7/tiles/{z}/{x}/{y}?access_token=" +
								L.mapbox.accessToken,
							{
								tileSize: 512,
								zoomOffset: -1,
								attribution:
									'© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
							}
						).addTo(map);
						//ends: mapbox title layer

						//start: legend custom image
						L.Control.Watermark = L.Control.extend({
							onAdd: function(map) {
								var img = L.DomUtil.create("img");

								img.src = legendImgUrl;

								return img;
							},

							onRemove: function(map) {
								// Nothing to do here
							}
						});

						L.control.watermark = function(opts) {
							return new L.Control.Watermark(opts);
						};

						//remove old legend and show new legend
						map.removeControl(myControl);
						myControl = L.control
							.watermark({ position: "bottomleft" })
							.addTo(map);
						//ends: legend custom image
					}
				);
			} else {
				ActivateWqiScore();
			}
		});
	});
}
