/**
 * Delays function from running immediatly
 * */
// eslint-disable-next-line import/prefer-default-export
export const Delay = (() => {
  let timer = 0;
  return (callback, ms) => {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
