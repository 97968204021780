/**
 * Hero Slider
 */
export default function ResponsiveTable() {
    const $ = window.jQuery;

    $(document).ready(function() {
        $(".main-table").clone(true).appendTo('.table-scroll').addClass('clone');

        // Comparison Table - Waterways
        const table = $('.comparison-table--waterways');
        const tableRow = '.table-wrap .main-table tbody tr';
        const clonedTable = $('.comparison-table--waterways .main-table.clone');

        table.on('mouseenter', tableRow, function (event) {
            const rowIndex = $(this).index();
            $(this).addClass('hover');
            clonedTable.find('tbody tr').eq(rowIndex).addClass('hover');
        }).on('mouseleave', tableRow,  function(){
            const rowIndex = $(this).index();
            $(this).removeClass('hover');
            clonedTable.find('tbody tr').eq(rowIndex).removeClass('hover');
        });
    });
}
