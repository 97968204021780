import { Delay } from "../utilities/delay";

/**
 * Location Selector Small JS. Located within .data-explorer
 * */
export default function LocationSelectorSmall() {
	const paneLeft = document.querySelector(".data-explorer .pane--left");

	if (paneLeft) {
		const locationSelector = document.querySelector(
			".location-selector--small"
		);
		const locationSelectorInner = locationSelector.querySelector(
			".location-selector--small .location-selector__inner"
		);
		const locationSelectorTrigger = locationSelector.querySelector(
			".location-selector-trigger"
		);
		let locationSelectorTriggerHeight;
		const locationSelectorSpacer = document.querySelector(
			".location-trigger-spacer"
		);
		// const buttonExplore = paneLeft.querySelector(".button-explore");
		const wqi = document.querySelector(".data-explorer .wqi");

		/**
		 * Get Panel height
		 */
		const getPaneLeftHeight = () => {
			let paneLeftHeight = paneLeft.offsetHeight;

			window.addEventListener("resize", () => {
				Delay(() => {
					paneLeftHeight = paneLeft.offsetHeight;
				}, 250);
			});

			return paneLeftHeight;
		};

		/**
		 * Set the default height of the location selector
		 * Adjust the .wqi accordingly so that the location selector does not overlap
		 */
		const setLocationSelectorTriggerHeight = () => {
			// console.log("resize");
			locationSelectorTriggerHeight =
				locationSelectorTrigger.offsetHeight;
			locationSelector.style.height = `${locationSelectorTriggerHeight}px`;
			locationSelectorSpacer.style.height = `${locationSelectorTriggerHeight}px`;
			wqi.style.height = `calc(100% - ${locationSelectorTriggerHeight}px)`;
			locationSelectorInner.style.height = `calc(100% - ${locationSelectorTriggerHeight}px)`;
		};

		/**
		 * Expand the location selector to the height of the browser window
		 */
		const openLocationSelector = () => {
			locationSelector.classList.add("expanded");
			locationSelector.style.height = `${getPaneLeftHeight()}px`;
		};

		/**
		 * Close the location selector to the default height
		 */
		const closeLocationSelector = () => {
			locationSelector.classList.remove("expanded");
			locationSelector.style.height = `${locationSelectorTriggerHeight}px`;
		};

		document.addEventListener("DOMContentLoaded", function() {
			setLocationSelectorTriggerHeight();
		});

		window.addEventListener("load", () => {
			window.dispatchEvent(new Event("resize"));
		});

		window.addEventListener("resize", () => {
			Delay(() => {
				setLocationSelectorTriggerHeight();
			}, 500);
		});

		locationSelectorTrigger.addEventListener("click", () => {
			if (locationSelector.classList.contains("expanded")) {
				closeLocationSelector();
			} else {
				openLocationSelector();
			}
		});

		/* buttonExplore.addEventListener('click', () => {
      closeLocationSelector();
    }); */
	}
}
