import { Delay } from '../utilities/delay';

/**
 * Show site information
 */
export default function SiteInformation() {
  function showBg() {
    let modal = document.querySelector('.modal-background');

    if ( !modal ) {
      modal = document.createElement("div");
      modal.classList.add('modal-background');
    }

    modal.classList.add('is-visible');

    const site = document.querySelector(".site");
    site.appendChild(modal);

    modal.addEventListener('click', () => {
      siteInfoClose('.site-header');
    });

  }

  function hideBg() {
    const modal = document.querySelector('.modal-background');
    modal.classList.remove('is-visible');
    modal.remove();
  }

  function siteInfoExpand(containerElm) {
    const container = document.querySelector(containerElm);
    if ( container ) {
      if ( container.classList.contains('expanded')) {
        container.classList.remove('expanded');
        hideBg();
      } else {
        container.classList.add('expanded');
        showBg();
      }
    }
  }

  function siteInfoClose(containerElm) {
    const container = document.querySelector(containerElm);

    if (container) {
      container.classList.remove('expanded');
      hideBg();
    }
  }


  const triggers = ['.js-trigger-small', '.js-trigger-large'];
  triggers.forEach(trigger => {
    const triggerElm = document.querySelector(trigger);
    if ( triggerElm ) {
      triggerElm.addEventListener('click', () => {
        siteInfoExpand('.site-header');
      });
    }
  });

  const closeButtons = ['.js-close-button--small', '.js-close-button--large'];
  closeButtons.forEach(closeButton => {
    const closeButtonElm = document.querySelector(closeButton);
    if ( closeButtonElm ) {
      closeButtonElm.addEventListener('click', () => {
        siteInfoClose('.site-header');
      });
    }
  });




  // window.addEventListener('resize', () => {
  //   Delay(() => {
  //     siteInfoExpand('.js-trigger-large', '.site-header--large');
  //     siteInfoExpand('.js-trigger-small', '.site-header--small');

  //     siteInfoClose('.js-close-button--large', '.site-header--large');
  //     siteInfoClose('.js-close-button--small', '.site-header--small');
  //   }, 250);
  // });

  // If user has scrolled, if they click the site info, scroll them to the top
  // add faded background, when clicked, close the site info
}
