const Highcharts = require('highcharts');

// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/pattern-fill')(Highcharts);

/**
* Highchart Charts
*/
export default function HighchartsCharts() {

	/**
	 * Wrap unit in span
	 * @param {string} unit
	 */
	function unitWrap(unit) {
		return "<span class='unit'>" + unit + "</span>";
	}

	/**
	* In order to synchronize tooltips and crosshairs, override the
	* built-in events with handlers defined on the parent element.
	*/

	const wqiChart = document.getElementById('wqi-chart');

	if (wqiChart) {
		['click', 'touchstart'].forEach((eventType) => {
			wqiChart.addEventListener(eventType, (e) => {
				let i;
				const wqiHighchart = Highcharts.charts[0];
				// Find coordinates within the chart
				const event = wqiHighchart.pointer.normalize(e);
				// Get the clicked point
				const point = wqiHighchart.series[0].searchPoint(event, true);

				if (point) {
					for (i = 0; i < Highcharts.charts.length; i += 1) {
						// NOT First chart ( WQI )
						if (i !== 0) {
							// Select the same X point on parameter charts
							const parameterChart = Highcharts.charts[i];
							const pointIndex = point.index;
							const points = parameterChart.series[0].data;

							// Deselect all points
							points.forEach((marker, index) => {
								parameterChart.series[0].data[index].select(
									false,
									false,
								);
							});

							// Mark newly selected point
							parameterChart.series[0].data[pointIndex].select(
								true,
								true,
							);

							//update applications
							const scoreValue = parameterChart.series[0].data[pointIndex].y;
							const scoreLabel = scoreValue === null ? 'n/a' : scoreValue;
							//default value for label
							var label = scoreValue === null ? '' : '';

							var chartName = parameterChart.renderTo.id;

							if (chartName == 'drinkingParaOne') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.drinkingParaOne',);

								//update unit
								var label = scoreValue === null ? '' : '';
							} else if (chartName == 'drinkingParaTwo') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.drinkingParaTwo',);

								//update unit
								var label = scoreValue === null ? '' : unitWrap('mg/L');
							} else if (chartName == 'drinkingParaThree') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.drinkingParaThree',);

								//update unit
								var label = scoreValue === null ? '' : unitWrap('mg/L');
							} else if (chartName == 'drinkingParaFour') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.drinkingParaFour',);

								//update unit
								var label = scoreValue === null ? '' : unitWrap('MPN/100ml');
							} else if (chartName == 'bathingParaOne') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.bathingParaOne',);

								//update unit
								var label = scoreValue === null ? '' : '';
							} else if (chartName == 'bathingParaTwo') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.bathingParaTwo',);

								//update unit
								var label = scoreValue === null ? '' : unitWrap('mg/L');
							} else if (chartName == 'bathingParaThree') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.bathingParaThree',);

								//update unit
								var label = scoreValue === null ? '' : unitWrap('mg/L');
							} else if (chartName == 'bathingParaFour') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.bathingParaFour',);

								//update unit
								var label = scoreValue === null ? '' : unitWrap('MPN/100ml');
							} else if (chartName == 'industrialParaOne') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.industrialParaOne',);

								//update unit
								var label = scoreValue === null ? '' : '';
							} else if (chartName == 'industrialParaTwo') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.industrialParaTwo',);

								//update unit
								var label = scoreValue === null ? '' : unitWrap('muS/cm');
							} else if (chartName == 'drinkingParaStatusOne') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.drinkingParaStatusOne',);
							} else if (chartName == 'drinkingParaStatusTwo') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.drinkingParaStatusTwo',);
							} else if (chartName == 'drinkingParaStatusThree') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.drinkingParaStatusThree',);
							} else if (chartName == 'drinkingParaStatusFour') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.drinkingParaStatusFour',);
							} else if (chartName == 'bathingParaStatusOne') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.bathingParaStatusOne',);
							} else if (chartName == 'bathingParaStatusTwo') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.bathingParaStatusTwo',);
							} else if (chartName == 'bathingParaStatusThree') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.bathingParaStatusThree',);
							} else if (chartName == 'bathingParaStatusFour') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.bathingParaStatusFour',);
							} else if (chartName == 'industrialParaStatusOne') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.industrialParaStatusOne',);
							} else if (chartName == 'industrialParaStatusTwo') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.industrialParaStatusTwo',);
							} else if (chartName == 'drinkingParaSafetyStatus') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.drinkingParaSafetyStatus',);
							} else if (chartName == 'bathingParaSafetyStatus') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.bathingParaSafetyStatus',);
							} else if (chartName == 'industrialParaSafetyStatus') {
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--application',)
								.querySelector('.industrialParaSafetyStatus',);
							} else {
								// Update Observed Value
								var scoreContainer = parameterChart.renderTo
								.closest('.accordion-item--parameter')
								.querySelector('.stat__value--observed');

								//update unit
								if( chartName == 'parameter-chart-bod' ) {
									var label = scoreValue === null ? '' : unitWrap('mg/L');
								} else if( chartName == 'parameter-chart-ec' ) {
									var label = scoreValue === null ? '' : unitWrap('muS/cm');
								} else if( chartName == 'parameter-chart-do' ) {
									var label = scoreValue === null ? '' : unitWrap('mg/L');
								} else if( chartName == 'parameter-chart-ph' ) {
									var label = scoreValue === null ? '' : '';
								} else if( chartName == 'parameter-chart-fc' ) {
									var label = scoreValue === null ? '' : unitWrap('MPN/100ml');
								} else if( chartName == 'parameter-chart-tc' ) {
									var label = scoreValue === null ? '' : unitWrap('MPN/100ml');
								}
							}

							// const scoreValue = parameterChart.series[0].data[pointIndex].y;
							// const scoreLabel = scoreValue === null ? 'n/a' : scoreValue;
							// const label = scoreValue === null ? '' : 'mg/l';

							var checkChartNameArrayForScore = ["drinkingParaStatusOne", "drinkingParaStatusTwo", "drinkingParaStatusThree", "drinkingParaStatusFour", "drinkingParaSafetyStatus", "bathingParaStatusOne", "bathingParaStatusTwo", "bathingParaStatusThree", "bathingParaStatusFour", "bathingParaSafetyStatus", "industrialParaStatusOne", "industrialParaStatusTwo", "industrialParaSafetyStatus"];
							var checkIfValueExistInArrayForScore = checkChartNameArrayForScore.includes(chartName);
							if( checkIfValueExistInArrayForScore != true ) {
								scoreContainer.innerHTML = `${Math.round(scoreLabel)} ${label}`;
							}

							const chartContainer = parameterChart.renderTo.id;
							const chartSelectedScoreValue = scoreValue;

							//application parameter status
							if( chartContainer.toUpperCase() === ("drinkingParaStatusOne").toUpperCase() ) {
							    if ( chartSelectedScoreValue >= 6.5 && chartSelectedScoreValue <= 8.5 ) {
							        window.jQuery("#drinkingParaStatusOne").css("background-color", "#7E9651"); // green
							    } else if ( chartSelectedScoreValue > 8.5 || chartSelectedScoreValue < 6.5 ) {
							        window.jQuery("#drinkingParaStatusOne").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("drinkingParaStatusTwo").toUpperCase() ) {
							    if ( chartSelectedScoreValue >= 6 ) {
							        window.jQuery("#drinkingParaStatusTwo").css("background-color", "#7E9651"); // green
							    } else {
							        window.jQuery("#drinkingParaStatusTwo").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("drinkingParaStatusThree").toUpperCase() ) {
							    if ( chartSelectedScoreValue <= 2 ) {
							        window.jQuery("#drinkingParaStatusThree").css("background-color", "#7E9651"); // green
							    } else {
							        window.jQuery("#drinkingParaStatusThree").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("drinkingParaStatusFour").toUpperCase() ) {
							    if ( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <= 50 ) {
							        window.jQuery("#drinkingParaStatusFour").css("background-color", "#7E9651"); // green
							    } else if ( chartSelectedScoreValue > 50 || chartSelectedScoreValue < 0 ) {
							        window.jQuery("#drinkingParaStatusFour").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("bathingParaStatusOne").toUpperCase() ) {
							    if ( chartSelectedScoreValue >= 6.5 && chartSelectedScoreValue <= 8.5 ) {
							        window.jQuery("#bathingParaStatusOne").css("background-color", "#7E9651"); // green
							    } else if ( chartSelectedScoreValue > 8.5 || chartSelectedScoreValue < 6.5 ) {
							        window.jQuery("#bathingParaStatusOne").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("bathingParaStatusTwo").toUpperCase() ) {
							    if ( chartSelectedScoreValue >= 5 ) {
							        window.jQuery("#bathingParaStatusTwo").css("background-color", "#7E9651"); // green
							    } else {
							        window.jQuery("#bathingParaStatusTwo").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("bathingParaStatusThree").toUpperCase() ) {
							    if ( chartSelectedScoreValue <= 3 ) {
							        window.jQuery("#bathingParaStatusThree").css("background-color", "#7E9651"); // green
							    } else {
							        window.jQuery("#bathingParaStatusThree").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("bathingParaStatusFour").toUpperCase() ) {
							    if ( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <= 500 ) {
							        window.jQuery("#bathingParaStatusFour").css("background-color", "#7E9651"); // green
							    } else if ( chartSelectedScoreValue > 500 || chartSelectedScoreValue < 0 ) {
							        window.jQuery("#bathingParaStatusFour").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("industrialParaStatusOne").toUpperCase() ) {
							    if ( chartSelectedScoreValue >= 6.5 && chartSelectedScoreValue <= 8.5 ) {
							        window.jQuery("#industrialParaStatusOne").css("background-color", "#7E9651"); // green
							    } else if ( chartSelectedScoreValue > 8.5 || chartSelectedScoreValue < 6.5 ) {
							        window.jQuery("#industrialParaStatusOne").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("industrialParaStatusTwo").toUpperCase() ) {
							    if ( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <= 2250 ) {
							        window.jQuery("#industrialParaStatusTwo").css("background-color", "#7E9651"); // green
							    } else if ( chartSelectedScoreValue > 2250 || chartSelectedScoreValue < 0 ) {
							        window.jQuery("#industrialParaStatusTwo").css("background-color", "#BF4045"); // red
							    }
							} else if( chartContainer.toUpperCase() === ("drinkingParaSafetyStatus").toUpperCase() ) {
								if( chartSelectedScoreValue == 0 ) {
									window.jQuery("#drinkingParaSafetyStatusImg").css("background-color", "#BF4045"); // red
									window.jQuery("#drinkingParaSafetyText").css("color", "#BF4045"); // red
									window.jQuery("#drinkingParaSafetyText").html("UNSAFE");
								} else {
									window.jQuery("#drinkingParaSafetyStatusImg").css("background-color", "#7EB21D"); // green
									window.jQuery("#drinkingParaSafetyText").css("color", "#7EB21D"); // green
									window.jQuery("#drinkingParaSafetyText").html("SAFE");
								}
							} else if( chartContainer.toUpperCase() === ("bathingParaSafetyStatus").toUpperCase() ) {
								if( chartSelectedScoreValue == 0 ) {
									window.jQuery("#bathingParaSafetyStatusImg").css("background-color", "#BF4045"); // red
									window.jQuery("#bathingParaSafetyText").css("color", "#BF4045"); // red
									window.jQuery("#bathingParaSafetyText").html("UNSAFE");
								} else {
									window.jQuery("#bathingParaSafetyStatusImg").css("background-color", "#7EB21D"); // green
									window.jQuery("#bathingParaSafetyText").css("color", "#7EB21D"); // green
									window.jQuery("#bathingParaSafetyText").html("SAFE");
								}
							} else if( chartContainer.toUpperCase() === ("industrialParaSafetyStatus").toUpperCase() ) {
								if( chartSelectedScoreValue == 0 ) {
									window.jQuery("#industrialParaSafetyStatusImg").css("background-color", "#BF4045"); // red
									window.jQuery("#industrialParaSafetyText").css("color", "#BF4045"); // red
									window.jQuery("#industrialParaSafetyText").html("UNSAFE");
								} else {
									window.jQuery("#industrialParaSafetyStatusImg").css("background-color", "#7EB21D"); // green
									window.jQuery("#industrialParaSafetyText").css("color", "#7EB21D"); // green
									window.jQuery("#industrialParaSafetyText").html("SAFE");
								}
							}


							if( chartContainer.toUpperCase() === ("parameter-chart-bod").toUpperCase() ) {
								if( chartSelectedScoreValue >= 2 && chartSelectedScoreValue <=  3 ) {
									window.jQuery("#bodIndicator").css("background-color", "#7E9651"); // green
								} else if( chartSelectedScoreValue > 3 || chartSelectedScoreValue < 2 ) {
									window.jQuery("#bodIndicator").css("background-color", "#BF4045"); // red
								}
							} else if( chartContainer.toUpperCase() === ("parameter-chart-ec").toUpperCase() ) {
								if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  2250 ) {
									window.jQuery("#ecIndicator").css("background-color", "#7E9651"); // green
								} else if( chartSelectedScoreValue > 2250 ) {
									window.jQuery("#ecIndicator").css("background-color", "#BF4045"); // red
								}
							} else if( chartContainer.toUpperCase() === ("parameter-chart-do").toUpperCase() ) {
								if( chartSelectedScoreValue >= 64 && chartSelectedScoreValue <=  77 ) {
									window.jQuery("#doIndicator").css("background-color", "#7E9651"); // green
								} else if( chartSelectedScoreValue > 77 || chartSelectedScoreValue < 64 ) {
									window.jQuery("#doIndicator").css("background-color", "#BF4045"); // red
								}
							} else if( chartContainer.toUpperCase() === ("parameter-chart-ph").toUpperCase() ) {
								if( chartSelectedScoreValue >= 6.5 && chartSelectedScoreValue <=  8.5 ) {
									window.jQuery("#phIndicator").css("background-color", "#7E9651"); // green
								} else if( chartSelectedScoreValue > 8.5 || chartSelectedScoreValue < 6.5 ) {
									window.jQuery("#phIndicator").css("background-color", "#BF4045"); // red
								}
							} else if( chartContainer.toUpperCase() === ("parameter-chart-fc").toUpperCase() ) {
								if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  500 ) {
									window.jQuery("#fcIndicator").css("background-color", "#7E9651"); // green
								} else if( chartSelectedScoreValue > 500 || chartSelectedScoreValue < 0 ) {
									window.jQuery("#fcIndicator").css("background-color", "#BF4045"); // red
								}
							} else if( chartContainer.toUpperCase() === ("parameter-chart-tc").toUpperCase() ) {
								if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  500 ) {
									window.jQuery("#tcindicator").css("background-color", "#7E9651"); // green
								} else if( chartSelectedScoreValue > 500 || chartSelectedScoreValue < 0 ) {
									window.jQuery("#tcindicator").css("background-color", "#BF4045"); // red
								}
							}/* else if( chartContainer.toUpperCase() === ("parameter-chart-cod").toUpperCase() ) {
								if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  3 ) {
									window.jQuery("#codIndicator").css("background-color", "#7E9651"); // green
								} else if( chartSelectedScoreValue > 3 && chartSelectedScoreValue <=  6 ) {
									window.jQuery("#codIndicator").css("background-color", "#FFA319"); // orange
								} else if( chartSelectedScoreValue > 6 ) {
									window.jQuery("#codIndicator").css("background-color", "#BF4045"); // red
								}
							} else if( chartContainer.toUpperCase() === ("parameter-chart-tss").toUpperCase() ) {
								if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  3 ) {
									window.jQuery("#tssIndicator").css("background-color", "#7E9651"); // green
								} else if( chartSelectedScoreValue > 3 && chartSelectedScoreValue <=  6 ) {
									window.jQuery("#tssIndicator").css("background-color", "#FFA319"); // orange
								} else if( chartSelectedScoreValue > 6 ) {
									window.jQuery("#tssIndicator").css("background-color", "#BF4045"); // red
								}
							} else if( chartContainer.toUpperCase() === ("parameter-chart-turbidity").toUpperCase() ) {
								if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  3 ) {
									window.jQuery("#turbidityIndicator").css("background-color", "#7E9651"); // green
								} else if( chartSelectedScoreValue > 3 && chartSelectedScoreValue <=  6 ) {
									window.jQuery("#turbidityIndicator").css("background-color", "#FFA319"); // orange
								} else if( chartSelectedScoreValue > 6 ) {
									window.jQuery("#turbidityIndicator").css("background-color", "#BF4045"); // red
								}
							}*/

							// window.jQuery("li.item-a").closest("ul").css( "background-color", "red" );*/

							// Update Criteria Limit
							//   const criteriaFrom = parameterChart.yAxis[0].plotLinesAndBands[0].options.from;
							//   const criteriaTo = parameterChart.yAxis[0].plotLinesAndBands[0].options.to;
							//   let criteriaLabel;

							//   if ( criteriaFrom === criteriaTo ) {
							// 	  criteriaLabel = `${criteriaFrom}mg/l`;
							//   } else {
							// 	criteriaLabel = `${criteriaFrom}-${criteriaTo}mg/l`;
							//   }
							//   const criteriaContainer = parameterChart.renderTo
							//     .closest('.accordion-item--parameter')
							//     .querySelector('.stat__value--criteria');
							//   criteriaContainer.innerHTML = `${criteriaLabel}`;
						}
					}
				}
			});
		});

		// Parameters
		// Get the data. The contents of the data file can be viewed at

		// fetching request get parameters
		let filterRiver = decodeURI((RegExp("river" + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]);
		let filterState = decodeURI((RegExp("state" + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]);
		let filterLocation = decodeURI((RegExp("location" + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]);
		var requestURL;
		if( filterRiver != 'null' && filterState != 'null' && filterLocation != 'null' )
		 	requestURL = apiPath+'data.php?location='+filterLocation+"&river="+filterRiver+"&state="+filterState;
		else
			requestURL = apiPath+'data.php';

		Highcharts.ajax({
			url: requestURL,
			dataType: 'text',
			success(response) {
				var parseResponse = JSON.parse(response);
				var chartResponseData = parseResponse['chartData'];

				var chartDataSets = parseResponse['chartData']['datasets'];
				if(typeof chartDataSets != typeof undefined ) {
					if( chartDataSets.length > 0 ) {
						chartResponseData.datasets.forEach((dataset, i) => {
							// Add X values
							dataset.data = Highcharts.map(dataset.data, (val, j) => [
								chartResponseData.xData[j],
								val,
							]);

							// Charts
							const chartName = chartResponseData.datasets[i].name;
							const chartContainer = chartResponseData.datasets[i].containerId;
							let parameterChart;

							//display unites in paramters maps tooltip
							var checkChartNameLabelArray = ['drinkingParaOne', 'drinkingParaTwo', 'drinkingParaThree', 'drinkingParaFour', 'bathingParaOne', 'bathingParaTwo', 'bathingParaThree', 'bathingParaFour' , 'industrialParaOne' , 'industrialParaTwo' , "drinkingParaStatusOne", "drinkingParaStatusTwo", "drinkingParaStatusThree", "drinkingParaStatusFour", "drinkingParaSafetyStatus", "bathingParaStatusOne", "bathingParaStatusTwo", "bathingParaStatusThree", "bathingParaStatusFour", "bathingParaSafetyStatus", "industrialParaStatusOne", "industrialParaStatusTwo", "industrialParaSafetyStatus","WQI"];
							var checkIfLabelValueExistInArray = checkChartNameLabelArray.includes(chartName);
							if( checkIfLabelValueExistInArray != true ) {
								if( chartName == 'BOD' ) {
									var labelUnits = 'mg/L';
								} else if( chartName == 'EC' ) {
									var labelUnits = unitWrap('muS/cm');
								} else if( chartName == 'DO' ) {
									var labelUnits = unitWrap('mg/L');
								} else if( chartName == 'pH' ) {
									var labelUnits = '';
								} else if( chartName == 'fc' ) {
									var labelUnits = unitWrap('MPN/100ml');
								} else if( chartName == 'tc' ) {
									var labelUnits = unitWrap('MPN/100ml');
								}
							}


							// Show all parameter charts
							if (chartName !== 'WQI') {
								const GoodCriteriaFrom = chartResponseData.datasets[i].plotBand[0].goodFrom;
								const GoodCriteriaTo = chartResponseData.datasets[i].plotBand[0].goodTo;
								const BadLowCriteriaFrom = chartResponseData.datasets[i].plotBand[0].badLowFrom;
								const BadLowCriteriaTo = chartResponseData.datasets[i].plotBand[0].badLowTo;
								const BadHighCriteriaFrom = chartResponseData.datasets[i].plotBand[0].badHighFrom;
								const BadHighCriteriaTo = chartResponseData.datasets[i].plotBand[0].badHighTo;
								parameterChart = Highcharts.chart(chartContainer, {
									chart: {
										marginLeft: 3,
										marginRight: 9,
										backgroundColor: 'transparent',
									},
									title: '',
									credits: {
										enabled: false,
									},
									legend: {
										enabled: false,
									},
									exporting: false,
									xAxis: {
										title: '',
										type: 'datetime',
										labels: '',
										gridLineWidth: 0,
										lineWidth: 0,
										tickWidth: 0,
									},
									yAxis: {
										title: '',
										labels: '',
										minorGridLineWidth: 0,
										gridLineWidth: 0,
										alternateGridColor: null,
										minorTickLength: 0,
										plotBands: [
											// BAD AREA LOW
											{
												color: {
													pattern: {

														path: {
														    d: 'M 0 6 L 6 0 M -1 1 L 1 -1 M 5 7 L 7 5',
														    stroke: 'rgba(191,64,69,0.25)',
														    strokeWidth: 1,
															fill: 'rgba(1,151,214, 0.0)',
													   },
													   width: 6,
													   height: 6,
												   }
											    },
												// Criteria Limit
												from: BadLowCriteriaFrom,
												to: BadLowCriteriaTo,
												label: '',
											},
											// BAD AREA HIGH
											{
												color: {
													pattern: {

														path: {
														    d: 'M 0 6 L 6 0 M -1 1 L 1 -1 M 5 7 L 7 5',
														    stroke: 'rgba(191,64,69,0.25)',
														    strokeWidth: 1,
															fill: 'rgba(1,151,214, 0.0)',
													   },
													   width: 6,
													   height: 6,
												   }
											    },
												// Criteria Limit
												from: BadHighCriteriaFrom,
												to: BadHighCriteriaTo,
												label: '',
											},
											// GOOD AREA
											{
												color: '#f2f9fc',
												// Criteria Limit
												from: GoodCriteriaFrom,
												to: GoodCriteriaTo,
												label: '',
											},
										],
									},
									plotOptions: {
										spline: {
											lineWidth: 2,
											marker: {
												enabled: true,
												radius: 1,
											},
										},
										series: {
											allowPointSelect: false,
											color: '#85C6E5',
											marker: {
												enabled: true,
												radius: 4,
												states: {
													select: {
														enabled: true,
														radius: 4,
														fillColor: '#004161',
													},
													hover: {
														enabled: true,
														radius: 4,
													},
												},
											},
											cursor: 'pointer',
											events: {
												afterAnimate() {
													const scoreValue = this.yData[
														this.yData.length - 1
													];
													const scoreLabel = scoreValue === null ? 'n/a' : scoreValue;
													var label = scoreValue === null ? '' : '';

													if (chartName == 'drinkingParaOne') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.drinkingParaOne',);

														//update unit
														var label = scoreValue === null ? '' : '';
													} else if (chartName == 'drinkingParaTwo') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.drinkingParaTwo',);

														//update unit
														var label = scoreValue === null ? '' : unitWrap('mg/L');
													} else if (chartName == 'drinkingParaThree') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.drinkingParaThree',);

														//update unit
														var label = scoreValue === null ? '' : unitWrap('mg/L');
													} else if (chartName == 'drinkingParaFour') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.drinkingParaFour',);

														//update unit
														var label = scoreValue === null ? '' : unitWrap('MPN/100ml');
													} else if (chartName == 'bathingParaOne') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.bathingParaOne',);

														//update unit
														var label = scoreValue === null ? '' : '';
													} else if (chartName == 'bathingParaTwo') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.bathingParaTwo',);

														//update unit
														var label = scoreValue === null ? '' : unitWrap('mg/L');
													} else if (chartName == 'bathingParaThree') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.bathingParaThree',);

														//update unit
														var label = scoreValue === null ? '' : unitWrap('mg/L');
													} else if (chartName == 'bathingParaFour') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.bathingParaFour',);

														//update unit
														var label = scoreValue === null ? '' : unitWrap('MPN/100ml');
													} else if (chartName == 'industrialParaOne') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.industrialParaOne',);

														//update unit
														var label = scoreValue === null ? '' : '';
													} else if (chartName == 'industrialParaTwo') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.industrialParaTwo',);

														//update unit
														var label = scoreValue === null ? '' : unitWrap('muS/cm');
													} else if (chartName == 'drinkingParaStatusOne') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.drinkingParaStatusOne',);
													} else if (chartName == 'drinkingParaStatusTwo') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.drinkingParaStatusTwo',);
													} else if (chartName == 'drinkingParaStatusThree') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.drinkingParaStatusThree',);
													} else if (chartName == 'drinkingParaStatusFour') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.drinkingParaStatusFour',);
													} else if (chartName == 'bathingParaStatusOne') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.bathingParaStatusOne',);
													} else if (chartName == 'bathingParaStatusTwo') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.bathingParaStatusTwo',);
													} else if (chartName == 'bathingParaStatusThree') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.bathingParaStatusThree',);
													} else if (chartName == 'bathingParaStatusFour') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.bathingParaStatusFour',);
													} else if (chartName == 'industrialParaStatusOne') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.industrialParaStatusOne',);
													} else if (chartName == 'industrialParaStatusTwo') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.industrialParaStatusTwo',);
													} else if (chartName == 'drinkingParaSafetyStatus') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.drinkingParaSafetyStatus',);
													} else if (chartName == 'bathingParaSafetyStatus') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.bathingParaSafetyStatus',);
													} else if (chartName == 'industrialParaSafetyStatus') {
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--application',)
														.querySelector('.industrialParaSafetyStatus',);
													} else {
														// Update Observed Value
														var scoreContainer = document.querySelector(`#${chartContainer}`,)
														.closest('.accordion-item--parameter',)
														.querySelector('.stat__value--observed',);

														if( chartName == 'BOD' ) {
															var label = scoreValue === null ? '' : unitWrap('mg/L');
															var criteriaLimit = "≤3 mg/l";
														} else if( chartName == 'EC' ) {
															var label = scoreValue === null ? '' : unitWrap('muS/cm');
															var criteriaLimit = "≤2250 muS/cm";
														} else if( chartName == 'DO' ) {
															var label = scoreValue === null ? '' : unitWrap('mg/L');
															var criteriaLimit = "≥6 mg/l";
														} else if( chartName == 'pH' ) {
															var label = scoreValue === null ? '' : '';
															var criteriaLimit = "6.5 – 8.5";
														} else if( chartName == 'fc' ) {
															var label = scoreValue === null ? '' : unitWrap('MPN/100ml');
															var criteriaLimit = "≤500 " + unitWrap("MPN/100 ml");
														} else if( chartName == 'tc' ) {
															var label = scoreValue === null ? '' : unitWrap('MPN/100ml');
															var criteriaLimit = "≤500 " + unitWrap("MPN/100 ml");
														}
													}

													// filling the circle colour
													const chartSelectedScoreValue = this.yData[
														this.yData.length - 1
													];

													//application parameter status
													if( chartContainer.toUpperCase() === ("drinkingParaStatusOne").toUpperCase() ) {
													    if ( chartSelectedScoreValue >= 6.5 && chartSelectedScoreValue <= 8.5 ) {
													        window.jQuery("#drinkingParaStatusOne").css("background-color", "#7E9651"); // green
													    } else if ( chartSelectedScoreValue > 8.5 || chartSelectedScoreValue < 6.5 ) {
													        window.jQuery("#drinkingParaStatusOne").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("drinkingParaStatusTwo").toUpperCase() ) {
													    if ( chartSelectedScoreValue >= 6 ) {
													        window.jQuery("#drinkingParaStatusTwo").css("background-color", "#7E9651"); // green
													    } else {
													        window.jQuery("#drinkingParaStatusTwo").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("drinkingParaStatusThree").toUpperCase() ) {
													    if ( chartSelectedScoreValue <= 2 ) {
													        window.jQuery("#drinkingParaStatusThree").css("background-color", "#7E9651"); // green
													    } else {
													        window.jQuery("#drinkingParaStatusThree").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("drinkingParaStatusFour").toUpperCase() ) {
													    if ( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <= 50 ) {
													        window.jQuery("#drinkingParaStatusFour").css("background-color", "#7E9651"); // green
													    } else if ( chartSelectedScoreValue > 50 || chartSelectedScoreValue < 0 ) {
													        window.jQuery("#drinkingParaStatusFour").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("bathingParaStatusOne").toUpperCase() ) {
													    if ( chartSelectedScoreValue >= 6.5 && chartSelectedScoreValue <= 8.5 ) {
													        window.jQuery("#bathingParaStatusOne").css("background-color", "#7E9651"); // green
													    } else if ( chartSelectedScoreValue > 8.5 || chartSelectedScoreValue < 6.5 ) {
													        window.jQuery("#bathingParaStatusOne").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("bathingParaStatusTwo").toUpperCase() ) {
													    if ( chartSelectedScoreValue >= 5 ) {
													        window.jQuery("#bathingParaStatusTwo").css("background-color", "#7E9651"); // green
													    } else {
													        window.jQuery("#bathingParaStatusTwo").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("bathingParaStatusThree").toUpperCase() ) {
													    if ( chartSelectedScoreValue <= 3 ) {
													        window.jQuery("#bathingParaStatusThree").css("background-color", "#7E9651"); // green
													    } else {
													        window.jQuery("#bathingParaStatusThree").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("bathingParaStatusFour").toUpperCase() ) {
													    if ( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <= 500 ) {
													        window.jQuery("#bathingParaStatusFour").css("background-color", "#7E9651"); // green
													    } else if ( chartSelectedScoreValue > 500 || chartSelectedScoreValue < 0 ) {
													        window.jQuery("#bathingParaStatusFour").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("industrialParaStatusOne").toUpperCase() ) {
													    if ( chartSelectedScoreValue >= 6.5 && chartSelectedScoreValue <= 8.5 ) {
													        window.jQuery("#industrialParaStatusOne").css("background-color", "#7E9651"); // green
													    } else if ( chartSelectedScoreValue > 8.5 || chartSelectedScoreValue < 6.5 ) {
													        window.jQuery("#industrialParaStatusOne").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("industrialParaStatusTwo").toUpperCase() ) {
													    if ( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <= 2250 ) {
													        window.jQuery("#industrialParaStatusTwo").css("background-color", "#7E9651"); // green
													    } else if ( chartSelectedScoreValue > 2250 || chartSelectedScoreValue < 0 ) {
													        window.jQuery("#industrialParaStatusTwo").css("background-color", "#BF4045"); // red
													    }
													} else if( chartContainer.toUpperCase() === ("drinkingParaSafetyStatus").toUpperCase() ) {
														if( chartSelectedScoreValue == 0 ) {
															window.jQuery("#drinkingParaSafetyStatusImg").css("background-color", "#BF4045"); // red
															window.jQuery("#drinkingParaSafetyText").css("color", "#BF4045"); // red
															window.jQuery("#drinkingParaSafetyText").html("UNSAFE");
														} else {
															window.jQuery("#drinkingParaSafetyStatusImg").css("background-color", "#7EB21D"); // green
															window.jQuery("#drinkingParaSafetyText").css("color", "#7EB21D"); // green
															window.jQuery("#drinkingParaSafetyText").html("SAFE");
														}
													} else if( chartContainer.toUpperCase() === ("bathingParaSafetyStatus").toUpperCase() ) {
														if( chartSelectedScoreValue == 0 ) {
															window.jQuery("#bathingParaSafetyStatusImg").css("background-color", "#BF4045"); // red
															window.jQuery("#bathingParaSafetyText").css("color", "#BF4045"); // red
															window.jQuery("#bathingParaSafetyText").html("UNSAFE");
														} else {
															window.jQuery("#bathingParaSafetyStatusImg").css("background-color", "#7EB21D"); // green
															window.jQuery("#bathingParaSafetyText").css("color", "#7EB21D"); // green
															window.jQuery("#bathingParaSafetyText").html("SAFE");
														}
													} else if( chartContainer.toUpperCase() === ("industrialParaSafetyStatus").toUpperCase() ) {
														if( chartSelectedScoreValue == 0 ) {
															window.jQuery("#industrialParaSafetyStatusImg").css("background-color", "#BF4045"); // red
															window.jQuery("#industrialParaSafetyText").css("color", "#BF4045"); // red
															window.jQuery("#industrialParaSafetyText").html("UNSAFE");
														} else {
															window.jQuery("#industrialParaSafetyStatusImg").css("background-color", "#7EB21D"); // green
															window.jQuery("#industrialParaSafetyText").css("color", "#7EB21D"); // green
															window.jQuery("#industrialParaSafetyText").html("SAFE");
														}
													}

													if( chartContainer.toUpperCase() === ("parameter-chart-bod").toUpperCase() ) {
														if( chartSelectedScoreValue >= 2 && chartSelectedScoreValue <=  3 ) {
															window.jQuery("#bodIndicator").css("background-color", "#7E9651"); // green
														} else if( chartSelectedScoreValue > 3 || chartSelectedScoreValue < 2 ) {
															window.jQuery("#bodIndicator").css("background-color", "#BF4045"); // red
														}
													} else if( chartContainer.toUpperCase() === ("parameter-chart-ec").toUpperCase() ) {
														if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  2250 ) {
															window.jQuery("#ecIndicator").css("background-color", "#7E9651"); // green
														} else if( chartSelectedScoreValue > 2250 ) {
															window.jQuery("#ecIndicator").css("background-color", "#BF4045"); // red
														}
													} else if( chartContainer.toUpperCase() === ("parameter-chart-do").toUpperCase() ) {
														if( chartSelectedScoreValue >= 64 && chartSelectedScoreValue <=  77 ) {
															window.jQuery("#doIndicator").css("background-color", "#7E9651"); // green
														} else if( chartSelectedScoreValue > 77 || chartSelectedScoreValue < 64 ) {
															window.jQuery("#doIndicator").css("background-color", "#BF4045"); // red
														}
													} else if( chartContainer.toUpperCase() === ("parameter-chart-ph").toUpperCase() ) {
														if( chartSelectedScoreValue >= 6.5 && chartSelectedScoreValue <=  8.5 ) {
															window.jQuery("#phIndicator").css("background-color", "#7E9651"); // green
														} else if( chartSelectedScoreValue > 8.5 || chartSelectedScoreValue < 6.5 ) {
															window.jQuery("#phIndicator").css("background-color", "#BF4045"); // red
														}
													} else if( chartContainer.toUpperCase() === ("parameter-chart-fc").toUpperCase() ) {
														if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  500 ) {
															window.jQuery("#fcIndicator").css("background-color", "#7E9651"); // green
														} else if( chartSelectedScoreValue > 500 || chartSelectedScoreValue < 0 ) {
															window.jQuery("#fcIndicator").css("background-color", "#BF4045"); // red
														}
													} else if( chartContainer.toUpperCase() === ("parameter-chart-tc").toUpperCase() ) {
														if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  500 ) {
															window.jQuery("#tcindicator").css("background-color", "#7E9651"); // green
														} else if( chartSelectedScoreValue > 500 || chartSelectedScoreValue < 0 ) {
															window.jQuery("#tcindicator").css("background-color", "#BF4045"); // red
														}
													}/* else if( chartContainer.toUpperCase() === ("parameter-chart-cod").toUpperCase() ) {
														if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  3 ) {
															window.jQuery("#codIndicator").css("background-color", "#7E9651"); // green
														} else if( chartSelectedScoreValue > 3 && chartSelectedScoreValue <=  6 ) {
															window.jQuery("#codIndicator").css("background-color", "#FFA319"); // orange
														} else if( chartSelectedScoreValue > 6 ) {
															window.jQuery("#codIndicator").css("background-color", "#BF4045"); // red
														}
													} else if( chartContainer.toUpperCase() === ("parameter-chart-tss").toUpperCase() ) {
														if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  3 ) {
															window.jQuery("#tssIndicator").css("background-color", "#7E9651"); // green
														} else if( chartSelectedScoreValue > 3 && chartSelectedScoreValue <=  6 ) {
															window.jQuery("#tssIndicator").css("background-color", "#FFA319"); // orange
														} else if( chartSelectedScoreValue > 6 ) {
															window.jQuery("#tssIndicator").css("background-color", "#BF4045"); // red
														}
													} else if( chartContainer.toUpperCase() === ("parameter-chart-turbidity").toUpperCase() ) {
														if( chartSelectedScoreValue >= 0 && chartSelectedScoreValue <=  3 ) {
															window.jQuery("#turbidityIndicator").css("background-color", "#7E9651"); // green
														} else if( chartSelectedScoreValue > 3 && chartSelectedScoreValue <=  6 ) {
															window.jQuery("#turbidityIndicator").css("background-color", "#FFA319"); // orange
														} else if( chartSelectedScoreValue > 6 ) {
															window.jQuery("#turbidityIndicator").css("background-color", "#BF4045"); // red
														}
													}*/

													/*const scoreValue = this.yData[
														this.yData.length - 1
													];
													const scoreLabel = scoreValue === null ? 'n/a' : scoreValue;
													const label = scoreValue === null ? '' : 'mg/l';*/

													var checkChartNameArrayForScore = ["drinkingParaStatusOne", "drinkingParaStatusTwo", "drinkingParaStatusThree", "drinkingParaStatusFour", "drinkingParaSafetyStatus", "bathingParaStatusOne", "bathingParaStatusTwo", "bathingParaStatusThree", "bathingParaStatusFour", "bathingParaSafetyStatus", "industrialParaStatusOne", "industrialParaStatusTwo", "industrialParaSafetyStatus"];
													var checkIfValueExistInArrayForScore = checkChartNameArrayForScore.includes(chartName);
													if( checkIfValueExistInArrayForScore != true ) {
														scoreContainer.innerHTML = `${Math.round(scoreLabel)} ${label}`;
													}

													const mostRecentMarker = this.data[
														this.data.length - 1
													];
													mostRecentMarker.select();

													// Update Criteria Limit
													var checkChartNameArray = ['drinkingParaOne', 'drinkingParaTwo', 'drinkingParaThree', 'drinkingParaFour', 'bathingParaOne', 'bathingParaTwo', 'bathingParaThree', 'bathingParaFour' , 'industrialParaOne' , 'industrialParaTwo' , "drinkingParaStatusOne", "drinkingParaStatusTwo", "drinkingParaStatusThree", "drinkingParaStatusFour", "drinkingParaSafetyStatus", "bathingParaStatusOne", "bathingParaStatusTwo", "bathingParaStatusThree", "bathingParaStatusFour", "bathingParaSafetyStatus", "industrialParaStatusOne", "industrialParaStatusTwo", "industrialParaSafetyStatus"];
													var checkIfValueExistInArray = checkChartNameArray.includes(chartName);
													if( checkIfValueExistInArray != true ) {
														const criteriaFrom = this.yAxis.plotLinesAndBands[0].options.from;
														const criteriaTo = this.yAxis.plotLinesAndBands[0].options.to;
														let criteriaLabel;

														if ( criteriaFrom === criteriaTo ) {
															// criteriaLabel = `${criteriaFrom} ${label}`;
															criteriaLabel = `${criteriaLimit}`;
														} else {
															// criteriaLabel = `${criteriaFrom}-${criteriaTo} ${label}`;
															criteriaLabel = `${criteriaLimit}`;
														}

														const criteriaContainer = parameterChart.renderTo
														.closest('.accordion-item--parameter')
														.querySelector('.stat__value--criteria');

														criteriaContainer.innerHTML = `${criteriaLabel}`;
													}
												},
											},
										},
									},
									tooltip: {
										xDateFormat: "%A, %b %e, %Y",
										valueDecimals: dataset.valueDecimals,
										backgroundColor: '#004161',
										shadow: false,
										borderColor: '#004161',
										borderRadius: 10,
										borderWidth: 1,
										useHTML: true,
										headerFormat: '<small>{point.key}</small><table>',
										pointFormat: '<tr><td class="tooltip-score">{point.y}</td>' + '<td class="tooltip-score-label">'+labelUnits+'</td></tr>',
										footerFormat: '</table>',
										padding: 0,
										style: {
											color: '#ffffff',
										},
									},
									series: [
										{
											data: dataset.data,
											name: dataset.name,
											type: 'spline',
											//   tooltip: {
											//     valueSuffix: ` ${dataset.unit}`,
											//   },
										},
									],
								});
							} else {
								const wqiScore = document.querySelector(
									'.wqi-chart-data__score',
								);
								const wqiDate = document.querySelector(
									'.wqi-chart-data__date',
								);
								const WQIChartID = chartResponseData.datasets[0].containerId;
								const WQIData = chartResponseData.datasets[0];

								Highcharts.chart(WQIChartID, {
									chart: {
										marginLeft: 12,
										marginRight: 9,
										spacingTop: 20,
										spacingBottom: 20,
										backgroundColor: 'transparent',
									},
									title: {
										text: '',
									},
									credits: {
										enabled: false,
									},
									legend: {
										enabled: false,
									},
									exporting: false,
									xAxis: {
										title: '',
										type: 'datetime',
										labels: '',
										gridLineWidth: 0,
										lineWidth: 0,
										tickWidth: 0,
									},
									yAxis: {
										title: '',
										labels: '',
										minorGridLineWidth: 0,
										gridLineWidth: 0,
									},
									plotOptions: {
										spline: {
											lineWidth: 2,
											marker: {
												enabled: true,
												radius: 1,
											},
										},
										series: {
											allowPointSelect: true,
											color: '#85C6E5',
											marker: {
												enabled: true,
												radius: 4,
												states: {
													select: {
														enabled: true,
														radius: 4,
														fillColor: '#004161',
													},
													hover: {
														enabled: true,
														radius: 4,
													},
												},
											},
											cursor: 'pointer',
											events: {
												afterAnimate() {
													const mostRecentScore = this.yData[
														this.yData.length - 1
													];
													const mostRecentDate = this.xData[
														this.xData.length - 1
													];
													const mostRecentMarker = this.data[
														this.data.length - 1
													];

													if( mostRecentScore >= 63 ) {
														//green
														window.jQuery("#wqiColor").css("background-color", "#7EB21D");
													} else if( mostRecentScore >= 50 && mostRecentScore < 63 ) {
														//yellow
														window.jQuery("#wqiColor").css("background-color", "#e0c715");
													} else if( mostRecentScore >= 38 && mostRecentScore < 50 ) {
														//orange
														window.jQuery("#wqiColor").css("background-color", "#EB9A14");
													} else if( mostRecentScore < 38 ) {
														//red
														window.jQuery("#wqiColor").css("background-color", "#BF4045");
													}


													wqiDate.innerHTML = Highcharts.dateFormat(
													'%B %d, %Y',
														mostRecentDate,
													);
													wqiScore.innerHTML = `${Math.round(mostRecentScore)}`;
													mostRecentMarker.select();

													//store date for showing data in map when clicked on application and parameter option
													var shortdate = `${Highcharts.dateFormat('%d-%m-%Y',mostRecentDate,)}`;
													window.jQuery("#hdnDateField").val(shortdate);

													/*console.log(
														`Activate: Most Recent Data, ${Highcharts.dateFormat(
														'%B %d, %Y',
															mostRecentDate,
														)}`,
													);*/
												},
											},
											point: {
												events: {
													select() {
														wqiDate.innerHTML = Highcharts.dateFormat(
															'%B %d, %Y',
															this.x,
														);
														wqiScore.innerHTML = `${Math.round(this.y)}`;
														var wqiScoreForColor = `${this.y}`;

														//change color of wqi
														if( wqiScoreForColor >= 63 ) {
															//green
															window.jQuery("#wqiColor").css("background-color", "#7EB21D");
														} else if( wqiScoreForColor >= 50 && wqiScoreForColor < 63 ) {
															//yellow
															window.jQuery("#wqiColor").css("background-color", "#e0c715");
														} else if( wqiScoreForColor >= 38 && wqiScoreForColor < 50 ) {
															//orange
															window.jQuery("#wqiColor").css("background-color", "#EB9A14");
														} else if( wqiScoreForColor < 38 ) {
															//red
															window.jQuery("#wqiColor").css("background-color", "#BF4045");
														}

														var shortdate = `${Highcharts.dateFormat('%d-%m-%Y',this.x,)}`;
														window.jQuery("#hdnDateField").val(shortdate);
														getMapDetailsWhenDateChanged(shortdate);
														/*console.log(
															`Activate: New Date Data for ${Highcharts.dateFormat(
															'%B %d, %Y',
															this.x,
															)}`,
														);*/
													},
												},
											},
										},
									},
									tooltip: {
										xDateFormat: "%A, %b %e, %Y",
										valueDecimals: 0,
										backgroundColor: '#004161',
										shadow: false,
										borderColor: '#004161',
										borderRadius: 10,
										borderWidth: 1,
										useHTML: true,
										headerFormat: '<small>{point.key}</small><table>',
										pointFormat: '<tr><td class="tooltip-score">{point.y}</td>' + '<td class="tooltip-score-label">WQI Score</td></tr>',
										footerFormat: '</table>',
										padding: 0,
										style: {
											color: '#ffffff',
										},
									},
									series: [
										{
											data: WQIData.data,
											name: WQIData.name,
											type: 'spline',
										},
									],
								});
							}
						});
					}
				}
				showMapWithDetails(parseResponse);

	            //display dropdown search status
	            if( typeof parseResponse['dropdownData']["location"] != typeof undefined && parseResponse['dropdownData']["location"].length > 0 ) {
		            var dropdownSearchStatusContent = parseResponse['dropdownData']['state']+"/"+parseResponse['dropdownData']['river']+"/"+parseResponse['dropdownData']['location'];
		            window.jQuery("#dropdownSearchStatus").html(dropdownSearchStatusContent);
	        	}
			},
			error(error) {
				// console.log(error);
			},
		});
	}
}


function showMapWithDetails(parseResponse) {
	var checkLatAndLong = parseResponse['mapData'].some(checkLatAndLongValues);

	//get sourceid value from url
	var sourceIdValue = "";
	let sourceIdValueFromUrl = decodeURI(
		(RegExp("source" + "=" + "(.+?)(&|$)").exec(
			location.search
		) || [, null])[1]
	);

	if (sourceIdValueFromUrl == "null") {
		sourceIdValue = 0;
	} else {
		sourceIdValue = sourceIdValueFromUrl;
	}

	if( checkLatAndLong == false ) {
		//do not contains any lat and long
		window.jQuery("#mapAlert").css("display","block");
	} else if( sourceIdValue == 1 ) {
		window.jQuery("#mapAlert").css("display","block");
	}

	if( parseResponse['mapData'].length > 0 ) {
		var latitude = parseResponse['mapData'][0]['latitude'];
		var longitude = parseResponse['mapData'][0]['longitude'];

		//when map loads
		if( latitude !== null && longitude !== null ) {
			map.on("load", function (event) {
				for(var i = 0; i < parseResponse['mapData'].length; i++) {
					//display map dots colors based on conditions
					var tooltipText = parseResponse['mapData'][i]["wqi"];
					var color;
					var lat;
					var long;
				    if( parseResponse['mapData'][i]["wqi"] >= 63 ) {
				        color = "#7EB21D"; //green shade
				        lat = parseResponse['mapData'][i]["latitude"];
				        long = parseResponse['mapData'][i]["longitude"];
				    } else if( parseResponse['mapData'][i]["wqi"] >= 50 && parseResponse['mapData'][i]["wqi"] < 63 ) {
				        color = "#e0c715"; //yellow shade
				        lat = parseResponse['mapData'][i]["latitude"];
				        long = parseResponse['mapData'][i]["longitude"];
				    } else if( parseResponse['mapData'][i]["wqi"] >= 38 && parseResponse['mapData'][i]["wqi"] < 50 ) {
				        color = "#EB9A14"; //orange shade
				        lat = parseResponse['mapData'][i]["latitude"];
				        long = parseResponse['mapData'][i]["longitude"];
				    } else if( parseResponse['mapData'][i]["wqi"] < 38 ) {
				        color = "#BF4045"; //red shade
				        lat = parseResponse['mapData'][i]["latitude"];
				        long = parseResponse['mapData'][i]["longitude"];
				    }

				    if( tooltipText != null && tooltipText != 0 ) {
					    var circle = L.circleMarker([parseFloat(lat), parseFloat(long)], {
					        stroke:true,
					        color: "#ffffff",
					        weight:0.2,
					        fillColor: color,
					        fillOpacity: 1,
					        radius: 10
					    }).bindTooltip(tooltipText).addTo(map);
					}
				}
			});
		}

		if( latitude == null ) {
			latitude = 13.09187; //default
		}
		if( longitude == null ) {
			longitude = 77.60722; //default
		}
	} else {
		var latitude = 13.09187; //default
		var longitude = 77.60722; //default
	}

	controlLayers(latitude,longitude,1);
}

function getMapDetailsWhenDateChanged(shortdate) {
	var selectedDateValue = shortdate;

	let filterRiver = decodeURI((RegExp("river" + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]);
	let filterLocation = decodeURI((RegExp("location" + '=' + '(.+?)(&|$)').exec(location.search)||[,null])[1]);

	//get sourceid value from url
	var sourceIdValue = "";
	let sourceIdValueFromUrl = decodeURI(
		(RegExp("source" + "=" + "(.+?)(&|$)").exec(
			location.search
		) || [, null])[1]
	);

	if (sourceIdValueFromUrl == "null") {
		sourceIdValue = 0;
	} else {
		sourceIdValue = sourceIdValueFromUrl;
	}

	// var elementName = accordionAttrValue;
    //fetch data for map according to matching date of clicked element in application and parameters
    window.jQuery.get(apiPath+"return-map-data.php", {
      	selectedDate: selectedDateValue,
      	selectedLocation: filterLocation,
      	selectedRiver: filterRiver
    },
    function(data, status) {
    	var parseResponse = JSON.parse(data);

    	if(parseResponse) {
	    	var checkLatAndLong = parseResponse.some(checkLatAndLongValues);

			if( checkLatAndLong == false ) {
				//do not contains any lat and long
				window.jQuery("#mapAlert").css("display","block");
			} else if( sourceIdValue == 1 ) {
				window.jQuery("#mapAlert").css("display","block");
			}

	    	//remove all layers from map
	    	map.eachLayer(function (layer) {
	         	map.removeLayer(layer);
	        });


	      	//leaflet map code
			if( parseResponse.length > 0 ) {
				//set longitude and color
				var latitude = parseResponse[0]['latitude'];
				var longitude = parseResponse[0]['longitude'];

				//when map loads
				if( latitude !== null && longitude !== null ) {
					for(var i = 0; i < parseResponse.length; i++) {
						var color;
						var lat;
						var long;
						//start: conditions
							var tooltipText = parseResponse[i]["wqi"];
							if( parseResponse[i]["wqi"] >= 63 ) {
						        color = "#7EB21D"; //green shade
						        lat = parseResponse[i]["latitude"];
						        long = parseResponse[i]["longitude"];
						    } else if( parseResponse[i]["wqi"] >= 50 && parseResponse[i]["wqi"] < 63 ) {
						        color = "#e0c715"; //yellow shade
						        lat = parseResponse[i]["latitude"];
						        long = parseResponse[i]["longitude"];
						    } else if( parseResponse[i]["wqi"] >= 38 && parseResponse[i]["wqi"] < 50 ) {
						        color = "#EB9A14"; //orange shade
						        lat = parseResponse[i]["latitude"];
						        long = parseResponse[i]["longitude"];
						    } else if( parseResponse[i]["wqi"] < 38 ) {
						        color = "#BF4045"; //red shade
						        lat = parseResponse[i]["latitude"];
						        long = parseResponse[i]["longitude"];
						    }
						//end: conditions

						//add circles for the
						/*var lat = parseResponse[i]["latitude"];
				        var long = parseResponse[i]["longitude"];*/

						if( lat !== null && long !== null ) {
					        if( tooltipText != null && tooltipText != 0 ) {
							    var circle = L.circleMarker([parseFloat(lat), parseFloat(long)], {
							    	stroke:true,
							        color: "#ffffff",
							        weight:0.2,
							        fillColor: color,
							        fillOpacity: 1,
							        radius: 10
							    }).bindTooltip(tooltipText).addTo(map);
						    }
						}
					}
				} else {
					var latitude = 13.09187; //default
					var longitude = 77.60722; //default
				}
	    	} else {
				var latitude = 13.09187; //default
				var longitude = 77.60722; //default
	    	}

	    	controlLayers(latitude,longitude,2);
    	}
    });
}

function controlLayers(latitude,longitude,actionFlag) {
	//start: mapbox title layer
		//access token of mapbox (given by ryanreynolds)
		L.mapbox.accessToken = 'pk.eyJ1IjoicnlhbnJleW5vbGRzIiwiYSI6ImNqemRkemRjdzA0dDQzYmp2d21waWR5NDkifQ.Sddp5xSThFCTBvUFqID1ZA';
		map.setView([latitude,longitude], 13);

		L.tileLayer(
	    	'https://api.mapbox.com/styles/v1/ryanreynolds/cjzde2kss30zh1cufkom0pfl7/tiles/{z}/{x}/{y}?access_token=' + L.mapbox.accessToken, {
	        tileSize: 512,
	        zoomOffset: -1,
	        attribution: '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
	    }).addTo(map);
    //ends: mapbox title layer

    //start: legend custom image
        L.Control.Watermark = L.Control.extend({
		    onAdd: function(map) {
		        var img = L.DomUtil.create('img');

		        img.src = "/assets/img/legend-wqi.svg";
		        img.style.width = '200px';

		        return img;
		    },

		    onRemove: function(map) {
		        // Nothing to do here
		    }
		});

		L.control.watermark = function(opts) {
		    return new L.Control.Watermark(opts);
		}

		//do not remove map for 1st time load
		if( actionFlag != 1 ) {
			map.removeControl(myControl);
		}
		myControl = L.control.watermark({ position: 'bottomleft' }).addTo(map);
	//ends: legend custom image
}


function checkLatAndLongValues(data) {
	//this function return false when data not match
	hasValue(data['longitude'])

	if( hasValue(data['latitude']) == true && hasValue(data['longitude']) == true  ) {
  		return data;
	}
}

function hasValue(data) {
	//this function return false when data not match
	return (data !== undefined) && (data !== null) && (data !== "");
}
