import $ from "jquery";
import "what-input";
import SimpleBar from "simplebar"; // eslint-disable-line
import { Foundation } from "foundation-sites/js/foundation.core";
import { Accordion } from "foundation-sites/js/foundation.accordion";
import { Tooltip } from "foundation-sites/js/foundation.tooltip";
import LocationSelectorSmall from "./components/location-selector-small";
import Wqi from "./components/wqi";
import Tabs from "./components/tabs";
import Accordions from "./components/accordions";
// import Select from './components/select';
import HighchartsCharts from "./components/highchartsCharts";
import SiteInformation from "./components/siteInformation";
import HeroSlider from "./components/heroSlider";
import ResponsiveTable from "./components/responsiveTable";

// require('foundation-sites');
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
// import './lib/foundation-explicit-pieces';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;

Foundation.addToJquery($);

Foundation.plugin(Accordion, "Accordion");
Foundation.plugin(Tooltip, "Tooltip");

$(document).foundation();

SiteInformation();

// Select();

LocationSelectorSmall();

Wqi();

Tabs();

Accordions();

HighchartsCharts();

HeroSlider();

ResponsiveTable();