import CloseOpenAccordion from '../partials/closeOpenAccordion';
import ActivateWqiScore from '../partials/activateWqiScore';
// import { Delay } from '../utilities/delay';

/**
 * WQI JS. Located within .data-explorer
 */
export default function Wqi() {
  const wqi = document.querySelector('.data-explorer .wqi');

  if (wqi) {
    const wqiHeader = wqi.querySelector('.wqi-header');
    const tabs = wqi.querySelectorAll('.tab');
    const accordions = wqi.querySelectorAll('.accordion-item-header');

    wqiHeader.addEventListener('click', () => {
      CloseOpenAccordion();

      ActivateWqiScore();
    }, false);

    tabs.forEach((tab) => {
      tab.addEventListener('click', () => {
        ActivateWqiScore();
        CloseOpenAccordion();
      });
    });

    accordions.forEach((accordion) => {
      accordion.addEventListener('click', () => {
        if (wqiHeader.classList.contains('is-active')) {
	  	wqiHeader.classList.remove('is-active');
        }
      });
    });
  }
}
