import Tabby from 'tabbyjs';
import CloseOpenAccordion from '../partials/closeOpenAccordion';

/**
 * Tabs
 */
export default function Tabs() {
  const tabsExist = document.querySelector('[data-tabs]');
  if (tabsExist) {
    // eslint-disable-next-line no-unused-vars
    const tabs = new Tabby('[data-tabs]');
  }
  /**
	   * When one of the applications/parameters tab is selected, activate the WQI score
	   */
  const applicationsParametersTabs = document.querySelector('#applications-parameters-tabs');
  if (applicationsParametersTabs) {
    applicationsParametersTabs.addEventListener('tabby', () => {
      CloseOpenAccordion();
    }, false);
  }
}
