/**
 * Activate WQI Score
 */
export default function ActivateWqiScore() {
  const wqiScore = document.querySelector('.wqi-header');
  // console.log(wqiScore);
  if (!wqiScore.classList.contains('is-active')) {
    wqiScore.classList.add('is-active');
    console.log('Activate: WQI Score');
  }
}
