/**
 * Close open accordion
 */
export default function CloseOpenAccordion() {
  // There should only be one open accordion at a time
  const openAccordion = document.querySelector('.accordion-item-header[aria-selected="true"]');
  if (openAccordion) {
    openAccordion.click();
  }
}
